import React from 'react';
import classNames from 'classnames';

import { TRANSACTION_TYPES } from '../../utils/constants';

import styles from './TransactionsFilter.module.scss';

const TransactionsFilter = ({ tabs, currentFilter, setFilter, setPage }) => {
  const lang = document.documentElement.lang;

  const handleFilterClick = (filter) => {
    setPage(1);
    setFilter(filter);
  };

  return (
    <div className={classNames(styles.block)}>
      <div className={classNames(styles.scroll)}>
        {tabs.length > 0 && (
          <button
            type="button"
            className={classNames('btn', 'btn--secondary', styles.btn, {
              'btn--dark': !currentFilter,
            })}
            onClick={() => handleFilterClick('')}
          >
            Всі
          </button>
        )}

        {tabs.map((tab) => (
          <button
            key={tab}
            type="button"
            className={classNames('btn', 'btn--secondary', styles.btn, {
              'btn--dark': currentFilter === tab,
            })}
            onClick={() => handleFilterClick(tab)}
          >
            {TRANSACTION_TYPES[tab].filterName[lang]}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TransactionsFilter;
