import { getRandomName } from '@utilities/helpers';

const orderChoice = () => {
  const anonimizerBtn = document.getElementById('anonimizerBtn');
  const personName = document.getElementById('id_contact_person');

  if (anonimizerBtn) {
    anonimizerBtn.addEventListener('click', () => {
      const generatedNickname = getRandomName();
      const isENLocale = window.location.href.toLowerCase().includes('/en/');

      if (isENLocale) {
        personName.value = 'KOLOninjus';
      } else {
        personName.value = generatedNickname;
      }

      dataLayer.push({
        event: 'congratulate_ninja_click',
        event_name: anonimizerBtn.dataset.name,
        event_id: anonimizerBtn.dataset.slug,
      });
    });
  }
};

export default orderChoice;
