import React from 'react';
import classNames from 'classnames';
import image from '@assets/images/empty-transactions.svg';

import styles from './EmptyTransactions.module.scss';

const EmptyTransactions = () => {
  return (
    <div className={classNames(styles.block)}>
      <div className={classNames(styles.image)}>
        <img src={image} alt="Empty transactions" />
      </div>
      <h2 className={classNames(styles.title)}>Очікуємо на хвилю донатів</h2>
      <p className={classNames(styles.text)}>Задонать, щоб стати першим у цьому списку</p>
    </div>
  );
};

export default EmptyTransactions;
