import { getRequiredFieldsValues, hasRequiredFieldError } from '@utilities/helpers';

import { matchPasswordValues } from './passwordField';

const updatePassword = () => {
  const changePasswordForm = document.getElementById('change-password-form');

  if (!changePasswordForm) return;

  const newPassword = changePasswordForm.querySelector('.js-password-new');
  const newPasswordConfirm = changePasswordForm.querySelector('.js-password-confirm');
  const passwordInputs = document.querySelectorAll('[type="password"]');
  const submitButton = changePasswordForm.querySelector('[type="submit"]');

  let isFormFilled = false;
  let hasErrors = true;

  if (!changePasswordForm) return;

  /**
   * Checking password fields value match
   */
  newPasswordConfirm.addEventListener('input', () => {
    matchPasswordValues(newPasswordConfirm, newPassword);
  });

  Array.from(passwordInputs).forEach((input) => {
    input.addEventListener('input', () => {
      isFormFilled = getRequiredFieldsValues(passwordInputs);
      hasErrors = hasRequiredFieldError(passwordInputs);

      if (isFormFilled && !hasErrors && newPassword.value === newPasswordConfirm.value) {
        submitButton.disabled = false;
      } else {
        submitButton.disabled = true;
      }
    });
  });
};

export default updatePassword;
