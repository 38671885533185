const setMenusPosition = () => {
  const mobileMenu = document.getElementById('mobile-menu');
  const servicesNavDesktop = document.getElementById('services-nav-desktop');
  const header = document.getElementById('header');

  if (!mobileMenu) return;

  let bannerHeight = 0;

  const setMobileNavPosition = () => {
    const banner = document.getElementById('targeted-banner');

    if (banner) {
      bannerHeight =
        window.scrollY > banner.offsetHeight ? 0 : banner.offsetHeight - window.scrollY;
      mobileMenu.style.top = `${bannerHeight}px`;
      servicesNavDesktop.style.top = `${bannerHeight + header.offsetHeight}px`;
      mobileMenu.style.height = `${window.innerHeight - bannerHeight}px`;
    } else {
      servicesNavDesktop.style.top = `${header.offsetHeight}px`;
    }
  };

  window.addEventListener('DOMContentLoaded', setMobileNavPosition);
  window.addEventListener('resize', setMobileNavPosition);
  window.addEventListener('scroll', setMobileNavPosition);
};

export default setMenusPosition;
