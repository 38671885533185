const modalWindow = (options) => {
  /**
   * Properties
   *
   * modalSelector: '.js-modal',
   * openSelector: '.js-modal-open',
   * onOpenCallback: () => { console.log('Modal opened') },
   * onCloseCallback: () => { console.log('Modal closed') },
   * autoOpen: false
   */

  const defaultOptions = {
    modalSelector: '.js-modal',
    openSelector: '.js-modal-open',
    onOpenCallback: () => {},
    onCloseCallback: () => {},
    autoOpen: false,
  };

  const settings = { ...defaultOptions, ...options };

  const modal = document.querySelector(settings.modalSelector);

  if (!modal) return;

  const modalWrapper = modal.parentElement;
  const openButtons = document.querySelectorAll(settings.openSelector);
  const closeButton = modal.querySelectorAll('.js-modal-close');
  const overlay = document.querySelector('.js-modal-overlay');

  const handleModalOpen = () => {
    modalWrapper.classList.add('is-opened');
    modal.classList.add('is-opened');
    overlay.classList.add('is-opened');
    settings.onOpenCallback();
  };

  const handleModalClose = () => {
    modal.classList.remove('is-opened');
    modalWrapper.classList.remove('is-opened');
    overlay.classList.add('overlay-fade-out');
    settings.onCloseCallback();
  };

  if (settings.autoOpen) {
    handleModalOpen();
  }

  openButtons.forEach((button) => {
    button.addEventListener('click', handleModalOpen);
  });

  closeButton.forEach((closeBtn) => {
    closeBtn.addEventListener('click', handleModalClose);
  });

  modalWrapper.addEventListener('click', (e) => {
    if (e.target === modalWrapper) {
      handleModalClose();
    }
  });

  document.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') {
      handleModalClose();
    }
  });

  overlay.addEventListener('animationend', (e) => {
    if (e.animationName === 'overlay-fade-out') {
      overlay.classList.remove('overlay-fade-out');
      overlay.classList.remove('is-opened');
    }
  });
};

export default modalWindow;
