import Modal from 'modal-vanilla';

const preloader = `
<span class="button__preloader">
  <svg class="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
    <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
  </svg>
</span>`;

const cancelSubscription = () => {
  const buttons = document.getElementsByClassName('js-unsubscribe-modal-opener');

  if (!buttons.length) return;

  [...buttons].forEach((button) => {
    button.addEventListener('click', () => {
      const modalEntity = document.getElementById('unsubModal');
      new Modal({ el: modalEntity }).show();
      const unsubLink = modalEntity.querySelector('.js-unsub-link');
      unsubLink.href = button.dataset.url;
      unsubLink.addEventListener('click', () => {
        unsubLink.style.color = 'transparent';
        unsubLink.insertAdjacentHTML('beforeend', preloader);
      });
    });
  });
};

export default cancelSubscription;
