import React from 'react';
import classNames from 'classnames';

import formatAmountValue from '../../utils/formatAmountValue';
import Heading from '../Heading/Heading';
import StatsInfoCard from '../StatsInfoCard/StatsInfoCard';

import styles from './StatsInfo.module.scss';

const StatsInfo = ({ max, avg, count, mono }) => {
  return (
    <div className={classNames(styles.container)}>
      <Heading>Статистика збору</Heading>

      <div className={classNames(styles.row, { [styles.mono]: mono })}>
        <StatsInfoCard
          title="Топ донат"
          stat={formatAmountValue(max) || 0}
          mark="₴"
          classMod="primary"
        />
        <StatsInfoCard title="Всього донатів" stat={formatAmountValue(count)} mark="шт." />
        <StatsInfoCard title="Середній донат" stat={formatAmountValue(avg)} mark="₴" />

        {!!mono && (
          <StatsInfoCard
            title="Mono-банка"
            stat={formatAmountValue(mono)}
            mark="₴"
            classMod="mono"
          />
        )}
      </div>
    </div>
  );
};

export default StatsInfo;
