const IMAGE_FILE_TYPES = [
  'image/gif',
  'image/jpeg',
  'image/jpg',
  'image/pjpeg',
  'image/png',
  'image/webp',
];

const SEXES = ['male', 'female'];

const NICKNAME_ADJECTIVES = {
  male: [
    'Жовто-блакитний',
    'Безжалісний',
    'Гордий',
    'Вільний',
    'Чарівний',
    'Гарячий',
    'Бунтівний',
    'Святковий',
    'Полумʼяний',
    'Багряний',
    'Кмітливий',
    'Зважений',
    'Красівий',
    'Вітчизняний',
    'Розлючений',
    'Романтичний',
    'Бандерський',
    'Швидкий',
    'Стрімкий',
    'Хтивий',
    'KOLOсальний',
    'Вірний',
    'Смертоносний',
    'Сміливий',
    'Незалежний',
    'Хвостатий',
  ],
  female: [
    'Красіва',
    'Свята',
    'Святкова',
    'Чарівна',
    'Замріяна',
    'Незламна',
    'Смілива',
    'Спритна',
    'Таємнича',
    'Переможна',
    'Підступна',
    'Охайна',
    'Жартівлива',
    'Мрійлива',
    'Відчайдушна',
    'Конотопська',
    'Супер',
    'Незламна',
    'Українська',
    'Невловима',
    'Фантастична',
    'Казкова',
    'Войовнича',
    'Жовто-блакитна',
    'Безжалісна',
    'Горда',
    'Вільна',
    'KOLOсальна',
    'Смертоносна',
    'Хвостата',
  ],
};

const NICKNAME_NOUNS = {
  male: [
    'Хаймарс',
    'Козак',
    'Джавелін',
    'Антонівський міст',
    'Насмас',
    'Палій',
    'Байрактар',
    'Патрон',
    'Шаман',
    'Костоправ',
    'Сапер',
    'NLAW',
    'Борис',
    'Бандера',
    'Степан',
    'Підпалюк',
    'Привид',
    'Карась',
    'Джонсонюк',
    'Майор',
    'Визволитель',
    'Залужний',
    'Генерал',
    'Мавік',
    'Волонтер',
    'Аутел',
  ],
  female: [
    'Джавеліна',
    'Госпітальєрка',
    'Бавовна',
    'Волонтерка',
    'Стугна',
    'Захисниця',
    'Киця',
    'Активістка',
    'Медикиня',
    'KOLOна',
    'Снайперка',
    'Бунтарка',
    'Розвідниця',
    'Валькірія',
    'Пташка',
    'Відьма',
    'Чайка',
    'Сокільниця',
    'Керівниця',
    'Кобза',
    'Чорнобаївка',
    'Мавка',
  ],
};

const CURRENCIES = {
  1: {
    name: 'uah',
    symbol: '₴',
  },
  2: {
    name: 'usd',
    symbol: '$',
  },
  3: {
    name: 'eur',
    symbol: '€',
  },
  4: {
    name: 'cad',
    symbol: '$',
  },
};

const CURRENCY_SUM = {
  uah: [1000, 1500, 2000],
  usd: [15, 20, 30],
  eur: [15, 20, 30],
  cad: [20, 25, 35],
};

export { IMAGE_FILE_TYPES, CURRENCIES, CURRENCY_SUM, NICKNAME_ADJECTIVES, NICKNAME_NOUNS, SEXES };
