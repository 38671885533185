import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import CampaignStatistics from './components/CampaignStatistics/CampaignStatistics';

(() => {
  const queryClient = new QueryClient();
  const campaignStatsRoot = document.getElementById('campaign-stats');

  if (!campaignStatsRoot) return;

  const root = createRoot(campaignStatsRoot);
  root.render(
    <QueryClientProvider client={queryClient}>
      <CampaignStatistics />
    </QueryClientProvider>,
  );
})();
