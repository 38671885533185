import Swiper, { Navigation, EffectCreative } from 'swiper';

const adventCalendar = () => {
  const getTargetSlide = (slider) => {
    const today = Number(slider.el.dataset.today);
    let targetSlide = 0;

    slider.slides.forEach((slide, index) => {
      if (Number(slide.dataset.date) === today) {
        targetSlide = index;
      }
    });

    return targetSlide;
  };

  const onSlideChangeHandler = (slider, targetSlide) => {
    const activeSlide = slider.activeIndex;
    const nextButton = slider.navigation.nextEl;

    if (activeSlide === targetSlide) {
      nextButton.disabled = true;
      slider.allowSlideNext = false; // eslint-disable-line
    } else {
      nextButton.disabled = false;
      slider.allowSlideNext = true; // eslint-disable-line
    }
  };

  const onSlideChangeLinkUpdate = (slider) => {
    const adventButtons = document.querySelectorAll('.js-advent-day-btn');
    const url = slider.slides[slider.activeIndex].dataset.url; // eslint-disable-line

    if (adventButtons.length) {
      adventButtons.forEach((button) => {
        button.href = url; // eslint-disable-line
      });
    }
  };

  const swiper = new Swiper('#advent-carousel', {
    modules: [Navigation, EffectCreative],
    init: false,
    effect: 'creative',
    centeredSlides: true,
    spaceBetween: 0,
    perspective: true,
    creativeEffect: {
      rotate: false,
      limitProgress: 2,
      prev: {
        scale: 0.91,
        translate: ['-8%', 0, 0],
      },
      next: {
        scale: 0.91,
        translate: ['8%', 0, 0],
      },
      perspective: false,
      progressMultiplier: 2,
    },
    grabCursor: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  swiper.on('init', (slider) => {
    const targetSlide = getTargetSlide(slider);

    slider.slideTo(targetSlide, 0);

    onSlideChangeLinkUpdate(slider);
    onSlideChangeHandler(slider, targetSlide);
  });

  swiper.init();

  swiper.on('slideChange', (slider) => {
    const targetSlide = getTargetSlide(slider);

    onSlideChangeLinkUpdate(slider);
    onSlideChangeHandler(slider, targetSlide);
  });
};

export default adventCalendar;
