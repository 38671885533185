import React from 'react';
import classNames from 'classnames';

import styles from './StatsInfoCard.module.scss';

const StatsInfoCard = ({ title, stat, mark, classMod }) => {
  return (
    <div className={classNames(styles.container, { [styles[classMod]]: classMod })}>
      <p className={classNames(styles.title)}>{title}</p>

      <div className={classNames(styles.row)}>
        <p className={classNames(styles.stat)}>{stat}</p>
        <p className={classNames(styles.mark)}>{mark}</p>
      </div>
    </div>
  );
};

export default StatsInfoCard;
