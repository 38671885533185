import { isDesktop } from 'react-device-detect';

const toggleServicesNav = () => {
  const desktopServicesBtn = document.getElementById('desktopServicesBtn');
  const servicesNavBtnMobile = document.getElementById('services-nav-btn-mobile');
  const mobileMenuBackBtn = document.getElementById('mobile-menu-back-btn');
  const mobileMenuBody = document.getElementById('mobile-menu-body');
  const navContainer = document.getElementById('mobile-menu');
  const anchors = document.getElementsByClassName('js-service-nav-anchor');

  if (!desktopServicesBtn || !servicesNavBtnMobile) return;

  const desktopNav = document.getElementById(desktopServicesBtn.dataset.toggle);
  const mobileNav = document.getElementById(servicesNavBtnMobile.dataset.toggle);

  desktopServicesBtn.addEventListener('click', () => {
    desktopServicesBtn.classList.toggle('is-active');
    desktopNav.classList.toggle('is-active');
  });

  servicesNavBtnMobile.addEventListener('click', () => {
    mobileMenuBackBtn.classList.add('is-active');
    mobileMenuBody.classList.add('is-hidden');
    mobileNav.classList.add('is-active');
  });

  mobileMenuBackBtn.addEventListener('click', () => {
    mobileMenuBackBtn.classList.remove('is-active');
    mobileMenuBody.classList.remove('is-hidden');
    mobileNav.classList.remove('is-active');
    servicesNavBtnMobile.classList.remove('is-active');
  });

  Array.from(anchors).forEach((anchor) => {
    anchor.addEventListener('click', () => {
      desktopNav.classList.remove('is-active');
      desktopServicesBtn.classList.remove('is-active');

      mobileMenuBackBtn.classList.remove('is-active');
      mobileMenuBody.classList.remove('is-hidden');
      mobileNav.classList.remove('is-active');
      navContainer.classList.remove('is-opened');
    });
  });

  if (isDesktop) {
    document.addEventListener('click', (event) => {
      const isClosest = event.target.closest(`#${desktopServicesBtn.dataset.toggle}`);

      if (
        !isClosest &&
        event.target !== desktopServicesBtn &&
        desktopNav.classList.contains('is-active')
      ) {
        desktopNav.classList.remove('is-active');
        desktopServicesBtn.classList.remove('is-active');
      }
    });

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        if (desktopNav.classList.contains('is-active')) {
          desktopNav.classList.remove('is-active');
          desktopServicesBtn.classList.remove('is-active');
        }
      }
    });
  }
};

export default toggleServicesNav;
