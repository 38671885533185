const countdownTimer = () => {
  function updateTimer() {
    // Get current time
    const now = new Date();

    // Set the end time to 23:59:59 of the current day
    const endTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
    const adventDayCta = document.getElementById('advent-day-cta');

    // Calculate the remaining time
    let timeDiff = endTime - now;
    if (timeDiff < 0) {
      // If the end time has passed, clear the interval and set the time difference to 0
      clearInterval(timerInterval); // eslint-disable-line
      timeDiff = 0;

      if (adventDayCta) {
        adventDayCta.remove();
      }
    }

    // Calculate hours, minutes, and seconds
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    // Format the time and update the timer element
    const formattedHours = `${String(hours).padStart(2, '0')}`;
    const formattedMinutes = `${String(minutes).padStart(2, '0')}`;
    const formattedSeconds = `${String(seconds).padStart(2, '0')}`;
    const countdown = document.getElementById('countdown');

    if (!countdown) return;

    const hoursElement = countdown.querySelector('#hours');
    const minutesElement = countdown.querySelector('#minutes');
    const secondsElement = countdown.querySelector('#seconds');

    hoursElement.innerText = formattedHours;
    minutesElement.innerText = formattedMinutes;
    secondsElement.innerText = formattedSeconds;
  }

  // Update the timer every second
  const timerInterval = setInterval(updateTimer, 1000);

  // Initial update
  updateTimer();
};

export default countdownTimer;
