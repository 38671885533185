const gaTargetedQuickDonate = () => {
  const forms = document.querySelectorAll('.data-quick-donate');

  if (!forms.length) return;

  Array.from(forms).forEach((form) => {
    form.addEventListener('submit', () => {
      const amount = form.querySelector('.data-amount').value;

      dataLayer.push({
        event: 'target_campaigns_quick_donate',
        currency: 'UAH',
        amount: amount,
      });
    });
  });
};

export default gaTargetedQuickDonate;
