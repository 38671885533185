import axios from 'axios';

axios.defaults.baseURL = window.location.origin;

export const getSWData = async () => {
  const request = await axios.get('https://swapi.dev/api/');
  return request.data;
};

export const getSWSectionData = async (section, page = 1) => {
  const request = await axios.get(`https://swapi.dev/api/${section}?page=${page}`);
  return request.data;
};

export const getTransactions = async (
  campaign = '',
  page = 1,
  filter = '',
  limit = 10,
  offset = 0,
) => {
  const request = await axios.get(
    `/api/v1/transactions/${campaign}?page=${page}&limit=${limit}&offset=${offset}${
      filter && `&transaction_type=${filter}`
    }`,
  );
  return request.data;
};
