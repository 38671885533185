import getDaysInMonth from '@utilities/getDaysInMonth';
import createSelectOption from '@utilities/createSelectOption';

const setDays = (daysSelect, monthSelect, yearSelect) => {
  const date = new Date();
  const daysAmount = getDaysInMonth(
    yearSelect ? yearSelect.value : date.getFullYear(),
    Number(monthSelect.value),
  );

  daysSelect.replaceChildren();

  for (let i = 0; i < daysAmount; i += 1) {
    const day = i + 1;

    daysSelect.appendChild(createSelectOption(day, day));
  }
};

/**
 *
 * @param {HTMLElement} select
 * Generats 100 years starting from year now
 */
const generateYears = (select) => {
  const date = new Date();
  const yearNow = date.getFullYear();

  for (let i = 0; i < 100; i++) {
    const year = yearNow - i;

    select.appendChild(createSelectOption(year, year));
  }
};

const setDaysInSelect = () => {
  const dateSelects = document.getElementsByClassName('js-date-select');
  const date = new Date();

  if (!dateSelects.length) {
    return;
  }

  Array.from(dateSelects).forEach((select) => {
    const daysSelect = select.querySelector('.js-day-select');
    const monthSelect = select.querySelector('.js-month-select');
    const yearSelect = select.querySelector('.js-year-select');

    setDays(daysSelect, monthSelect, yearSelect);

    if (monthSelect.dataset.month) {
      monthSelect.value = monthSelect.dataset.month;
    } else {
      monthSelect.value = date.getMonth() + 1;
    }

    if (daysSelect.dataset.day) {
      daysSelect.value = daysSelect.dataset.day;
    } else {
      daysSelect.value = date.getDate();
    }

    monthSelect.addEventListener('change', () => {
      setDays(daysSelect, monthSelect, yearSelect);
    });

    if (yearSelect) {
      yearSelect.addEventListener('change', () => {
        setDays(daysSelect, monthSelect, yearSelect);
      });

      /**
       * Fills only selects with the class name `js-year-generated` with generated years
       */
      if (yearSelect.classList.contains('js-year-generated')) {
        generateYears(yearSelect);

        if (yearSelect.dataset.year) {
          yearSelect.value = yearSelect.dataset.year;
        }
      }
    }
  });
};

export default setDaysInSelect;
