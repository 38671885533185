const calculateInputWidth = (input, minWidth) => {
  const getInputWidth = (inputElement) => {
    // Create a temporary span element to measure the width of the text
    const span = document.createElement('span');

    let inputWidth = 0;

    span.style.visibility = 'hidden';
    span.style.whiteSpace = 'pre'; // Preserve whitespace to get accurate width
    span.style.fontSize = window.getComputedStyle(inputElement).getPropertyValue('font-size');
    span.style.fontWeight = 'bold';
    span.innerText = inputElement.value;

    // Add the span to the DOM and measure its width
    document.body.appendChild(span);
    inputWidth = span.offsetWidth;
    document.body.removeChild(span);

    return inputWidth;
  };

  const updateInputWidth = (inputEl, minW) => {
    // Get the width of the input's text content
    const inputWidth = getInputWidth(inputEl);

    // Set the input field's width to the calculated width
    inputEl.style.width = `${Math.max(inputWidth, minW)}px`; // eslint-disable-line
  };

  updateInputWidth(input, minWidth);
};

export default calculateInputWidth;
