import isTouchEnabled from './isTouchDevice';
import setSvgSprite from './svgSpriteEmitter';
import backToTop from './backToTop';
import mobileNavToggle from './mobileNavToggle';
import toggleServicesNav from './toggleServicesNav';
import customScrollbar from './customScrollbar';
import paymentAmountSelection from './paymentAmountSelection';
import setOrderAmount from './orderAmount';
import copyToClipboard from './copyToClipboard';
import collapsible from './collapsible';
import tabs from './tabs';
import setDaysInSelect from './setDaysInSelect';
import alert from './alert';
import langs from './langs';
import { targetedBanner, closeTargetedBanner, targetedBannerModal } from './targetedBanner';
import donationWidget from './donationWidget';
import accordion from './accordion';
import setMenusPosition from './setMenusPosition';

(() => {
  if (isTouchEnabled()) {
    document.getElementsByTagName('html')[0].classList.add('is-touch');
  }

  setSvgSprite();
  backToTop();
  mobileNavToggle();
  setMenusPosition();
  toggleServicesNav();
  customScrollbar();
  paymentAmountSelection();
  setOrderAmount();
  copyToClipboard();
  collapsible();
  tabs();
  setDaysInSelect();
  alert();
  langs();
  targetedBanner();
  closeTargetedBanner();
  targetedBannerModal();
  donationWidget();

  // TODO: Re-write to collapsible
  accordion();
})();
