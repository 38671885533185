const togglePasswordVisibility = () => {
  const passwordFields = document.getElementsByClassName('js-password');

  Array.from(passwordFields).forEach((field) => {
    const input = field.querySelector('[type="password"]');
    const toggleBtn = field.querySelector('.js-password-toggle');
    const iconShow = field.querySelector('.js-icon-show');
    const iconHide = field.querySelector('.js-icon-hide');

    toggleBtn.addEventListener('click', () => {
      if (input.type === 'password') {
        input.type = 'text';
        iconShow.style.display = 'none';
        iconHide.style.display = 'block';
      } else {
        input.type = 'password';
        iconShow.style.display = 'block';
        iconHide.style.display = 'none';
      }
    });
  });
};

const validatePasswordInput = (event) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/;
  const input = event.target;
  const errorText = input.parentNode.querySelector('.js-password-validation-error');

  if (!regex.test(input.value)) {
    input.classList.add('has-error');
    errorText.classList.add('is-active');
  } else {
    input.classList.remove('has-error');
    errorText.classList.remove('is-active');
  }
};

const matchPasswordValues = (checkInput, sourceInput) => {
  const errorMessage = checkInput.parentElement.querySelector('.js-password-error');

  if (checkInput.value !== sourceInput.value) {
    errorMessage.classList.add('is-active');
  } else {
    errorMessage.classList.remove('is-active');
  }
};

export { togglePasswordVisibility, validatePasswordInput, matchPasswordValues };
