const collapsible = (options = {}) => {
  const defaultOptions = {
    duration: 200,
    iconRotationAngle: 180,
  };
  const settings = { ...defaultOptions, ...options };

  const coll = document.querySelectorAll('.js-collapsible');

  coll.forEach((container) => {
    const button = container.querySelector('.js-collapsible-button');
    const content = container.querySelector('.js-collapsible-content');
    const icon = container.querySelector('.js-collapsible-icon');

    button.addEventListener('click', () => {
      button.classList.toggle('is-active');

      if (content.style.maxHeight) {
        content.style.maxHeight = null;

        if (icon) {
          icon.style.transform = `rotate(0deg)`;
        }
      } else {
        content.style.transitionDuration = `${settings.duration}ms`;
        content.style.maxHeight = `${content.scrollHeight}px`;

        if (icon) {
          icon.style.transform = `rotate(${settings.iconRotationAngle}deg)`;
        }
      }
    });
  });
};

export default collapsible;
