import _ from 'lodash';

const saveChangesButtonState = () => {
  const form = document.getElementById('profile-settings-form');

  if (!form) {
    return;
  }

  const userNameElement = document.getElementById('user-name');
  const userBirthDayElement = document.getElementById('user-day');
  const userBirthMonthElement = document.getElementById('user-month');
  const userBirthYearElement = document.getElementById('user-year');
  const selects = form.querySelectorAll('.js-profile-select');
  const submitButton = form.querySelector('[type="submit"]');

  const getUserData = () => {
    return {
      name: userNameElement.value,
      day: userBirthDayElement.value,
      month: userBirthMonthElement.value,
      year: userBirthYearElement.value,
    };
  };

  const setSubmitButtonState = (oldUser) => {
    const updatedUser = getUserData();

    submitButton.disabled = _.isEqual(oldUser, updatedUser);
  };

  const user = getUserData();

  submitButton.disabled = true;

  userNameElement.addEventListener('input', () => {
    setSubmitButtonState(user);
  });

  selects.forEach((select) => {
    select.addEventListener('change', () => {
      setSubmitButtonState(user);
    });
  });
};

export default saveChangesButtonState;
