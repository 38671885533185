import { CURRENCIES, CURRENCY_SUM } from '@utilities/constants';

const setOrderAmount = () => {
  const orderAmount = document.querySelector('.js-order-amount');
  const orderValues = document.getElementsByClassName('js-order-value');
  const orderViews = document.getElementsByClassName('js-order-view');
  const currency = document.querySelector('.js-order-currency');

  const unCheckOrder = (orders) => {
    [...orders].forEach((order) => {
      if (order.checked) {
        order.checked = false; // eslint-disable-line
      }
    });
  };

  const selectOrderAmount = (target, input) => {
    target.value = input.value; // eslint-disable-line
    input.checked = true; // eslint-disable-line
  };

  const setCurrency = (currencySelect, values) => {
    [...values].forEach((value, index) => {
      if (value.dataset.currency === '₴') {
        value.innerHTML += value.dataset.currency; // eslint-disable-line
      } else {
        value.innerHTML = value.dataset.currency + value.innerHTML; // eslint-disable-line
      }

      if (currencySelect) {
        const currencyAbbreviation =
          currencySelect.options[currencySelect.selectedIndex].text.toLowerCase();

        value.dataset.currency = CURRENCIES[currencyAbbreviation]; // eslint-disable-line
        value.innerHTML = CURRENCY_SUM[currencyAbbreviation][index]; // eslint-disable-line

        orderValues[index].value = CURRENCY_SUM[currencyAbbreviation][index];
      }
    });
  };

  if (orderValues.length > 0) {
    // Set checked value on page load
    setCurrency(currency, orderViews);
    selectOrderAmount(orderAmount, orderValues[1]);

    // Set values on change
    [...orderValues].forEach((input) => {
      input.addEventListener('change', (event) => {
        selectOrderAmount(orderAmount, event.target);
      });
    });

    orderAmount.addEventListener('input', (event) => {
      const equalOrders = [...orderValues].filter((input) => event.target.value === input.value);

      if (equalOrders.length) {
        equalOrders[0].checked = true;
      } else {
        unCheckOrder(orderValues);
      }
    });

    if (currency) {
      currency.addEventListener('change', () => {
        setCurrency(currency, orderViews);
        selectOrderAmount(orderAmount, orderValues[1]);
      });
    }
  }
};

export default setOrderAmount;
