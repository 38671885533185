const gaChatBotSubscription = () => {
  const btn = document.querySelector('.data-chatbot-btn');

  if (!btn) return;

  btn.addEventListener('click', () => {
    dataLayer.push({
      event: 'subscribe_chatbot',
    });
  });
};

export default gaChatBotSubscription;
