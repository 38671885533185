const alert = () => {
  const alertElements = document.getElementsByClassName('js-alert');

  if (!alertElements.length) {
    return;
  }

  Array.from(alertElements).forEach((alertElement) => {
    const closeBtn = alertElement.querySelector('.js-alert-close');

    closeBtn.addEventListener('click', () => {
      alertElement.style.display = 'none'; // eslint-disable-line
    });

    setTimeout(() => {
      alertElement.style.display = 'none'; // eslint-disable-line
    }, 2000);
  });
};

export default alert;
