import expandable from '@common/expandable';

const showMoreSubscriptions = () => {
  const subscriptionsList = document.getElementById('subscriptions');

  if (subscriptionsList) {
    expandable(subscriptionsList, { visibleRows: 4 });
  }
};

export default showMoreSubscriptions;
