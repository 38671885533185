const gaCampaignCardClick = () => {
  const cards = document.getElementsByClassName('data-campaign-card');

  [...cards].forEach((card, index) => {
    card.addEventListener('click', () => {
      dataLayer.push({
        event: 'congratulate_block_click',
        list_position: index,
        event_name: card.dataset.name,
        event_id: card.dataset.slug,
      });
    });
  });
};

export default gaCampaignCardClick;
