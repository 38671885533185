import confetti from 'canvas-confetti';

const confettiEffect = () => {
  const canvas = document.createElement('canvas');
  const section = document.getElementById('thank_you_section');
  const options = {
    particleCount: 100,
    spread: 160,
  };

  if (!section) return;

  canvas.classList.add('confetti-canvas');

  const myConfetti = confetti.create(canvas, {
    resize: true,
    useWorker: true,
  });

  const removeConfetti = () => {
    myConfetti.reset();
    canvas.remove();
  };

  const generateConfetti = (callback) => {
    setTimeout(() => {
      myConfetti(options);
    }, 1000);

    setTimeout(callback, 5000);
  };

  document.addEventListener('DOMContentLoaded', () => {
    section.appendChild(canvas);

    generateConfetti(removeConfetti);
  });
};

export default confettiEffect;
