const userProfileHeaderToggle = () => {
  const PROFILE_TOGGLE_BTN = 'profile-toggle-btn';
  const PROFILE_DROPDOWN = 'profile-dropdown';

  const toggleBtn = document.getElementById(PROFILE_TOGGLE_BTN);
  const dropdown = document.getElementById(PROFILE_DROPDOWN);

  if (!toggleBtn) return;

  toggleBtn.addEventListener('click', (event) => {
    event.stopPropagation();
    toggleBtn.classList.toggle('is-active');
    dropdown.classList.toggle('is-opened');
  });

  document.addEventListener('click', (event) => {
    const isClosest = event.target.closest('#profile-dropdown');

    if (!isClosest && dropdown.classList.contains('is-opened')) {
      toggleBtn.classList.remove('is-active');
      dropdown.classList.remove('is-opened');
    }
  });
};

export default userProfileHeaderToggle;
