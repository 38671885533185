import React from 'react';
import classNames from 'classnames';
import { isMobileOnly } from 'react-device-detect';

import Transaction from '../Transaction/Transaction';

import styles from './TransactionsList.module.scss';

const TransactionsList = ({ transactions, transactionsAmount }) => {
  const tableHeight = transactionsAmount * 38;

  return (
    <div
      className={classNames(styles.table)}
      style={{ height: !isMobileOnly && `${tableHeight}px` }}
    >
      {transactions.map((transaction) => (
        <Transaction key={transaction.created_at} transaction={transaction} />
      ))}
    </div>
  );
};

export default TransactionsList;
