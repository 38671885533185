const spoiler = () => {
  const particlesContainer = document.getElementById('spoilerParticles');
  const spoilerText = document.getElementById('spoilerText');

  if (!particlesContainer) return;

  (async () => {
    await tsParticles.load('spoilerParticles', {
      background: {
        color: '#0085FF',
      },
      particles: {
        number: {
          value: 400,
        },
        color: {
          value: '#ffffff',
        },
        shape: {
          type: 'circle',
        },
        opacity: {
          value: { min: 0, max: 1 },
          animation: {
            count: 1,
            enable: true,
            speed: 1,
            startValue: 'min',
            sync: false,
          },
        },
        size: {
          value: { min: 0.5, max: 1.5 },
        },
        move: {
          enable: true,
          speed: {
            min: 0.5,
            max: 1,
          },
          direction: 'none',
          straight: false,
          outModes: {
            default: 'out',
          },
        },
      },
    });
  })();

  setTimeout(() => {
    particlesContainer.children[0].style.position = 'absolute';
  }, 300);

  particlesContainer.addEventListener('click', (e) => {
    e.currentTarget.classList.add('is-hidden');
    spoilerText.classList.remove('is-hidden');

    setTimeout(() => {
      particlesContainer.classList.remove('is-hidden');
      spoilerText.classList.add('is-hidden');
    }, 4000);
  });
};

export default spoiler;
