import expandable from '@common/expandable';

const showMoreGreetings = () => {
  const greetingsExpandable = document.getElementById('greetings');

  if (greetingsExpandable) {
    expandable(greetingsExpandable, { visibleRows: 10 });
  }
};

export default showMoreGreetings;
