const addToCalendar = () => {
  const START_TIME = '12:00:00';
  const DESCRIPTION = `[p]Цей івент, це нагадування про визначний день, 
  який ми запланували відсвяткувати разом, і зібрати з цього приводу 
  коштів на ЗСУ 🇺🇦[/p][strong]Декілька кроків і можна святкувати:[/strong][ol][li]Створюєш 
  Свято у нас на сайті — [url]https://happy.koloua.com/campaigns/create[/url][/li][li]Запрошуєш 
  друзів до привітання 🎁 — ділишся святом у своїх соціальних мережах і не забуваєш про хештег 
  #happykolo[/li][li]Отримуєш насолоду від привітань які приходять тобі в телеграм від 
  KOLObot 🤖 [url]https://t.me/kolofundbot[/url][/li][/ol][p]Святкуй з користю для 
  Країни разом з KOLO 🎉[/p]`;

  const config = {
    name: 'Святкування у Святковому KOLO',
    description: DESCRIPTION,
    startTime: START_TIME,
    endTime: '13:00:00',
    options: ['Google'],
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    trigger: 'click',
    iCalFileName: 'Reminder-Event',
  };

  // HTML elements
  const day = document.getElementById('id_start_day');
  const month = document.getElementById('id_start_month');
  const button = document.getElementById('add_to_calendar');

  // Current date in string and milliseconds
  const currentDate = new Date();
  const currentDateMs = currentDate.getTime();

  if (!day || !month || !button) return;

  // Changable date values
  let currentYear = currentDate.getFullYear();
  let selectedDateString = `${currentYear}-${month.value}-${day.value}`;
  let selectedDateMs = new Date(`${selectedDateString} ${START_TIME}`).getTime();

  const changeDates = () => {
    currentYear = currentDate.getFullYear();
    selectedDateString = `${currentYear}-${month.value}-${day.value}`;
    selectedDateMs = new Date(`${selectedDateString} ${START_TIME}`).getTime();

    if (selectedDateMs < currentDateMs) {
      currentYear = currentDate.getFullYear() + 1;
      selectedDateString = `${currentYear}-${month.value}-${day.value}`;
    }
  };

  changeDates();

  day.addEventListener('change', changeDates);
  month.addEventListener('change', changeDates);

  button.addEventListener('click', () =>
    atcb_action({ ...config, startDate: selectedDateString }, button),
  );
};

export default addToCalendar;
