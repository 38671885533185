import React from 'react';
import classNames from 'classnames';

import styles from './Heading.module.scss';

const Heading = ({ children }) => {
  return <h2 className={classNames(styles.heading)}>{children}</h2>;
};

export default Heading;
