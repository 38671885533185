const delay = ({ time = 1000, callback = () => {} }) => {
  const delayCallback = async () => {
    await new Promise((resolve) => {
      setTimeout(resolve, time);
    });
    callback();
  };
  delayCallback();
};

export default delay;
