import { IMAGE_FILE_TYPES } from '@utilities/constants';
import { isValidFileType } from '@utilities/helpers';

const uploadUserPhoto = () => {
  const fileInput = document.getElementById('id_photo');
  const photoPreview = document.getElementById('photoPreview');
  const photoContainer = document.getElementById('photo_container');
  const restrictionMessage = document.getElementById('restriction_message');

  if (photoPreview && photoPreview.dataset.url) {
    const image = document.createElement('img');

    image.src = photoPreview.dataset.url;
    photoContainer.classList.remove('is-error');
    restrictionMessage.classList.remove('is-error');
    photoPreview.appendChild(image);
  }

  const loadPhoto = () => {
    const file = fileInput.files[0];

    while (photoPreview.firstChild) {
      photoPreview.removeChild(photoPreview.firstChild);
      photoContainer.classList.remove('is-error');
    }

    if (isValidFileType(file, IMAGE_FILE_TYPES)) {
      const image = document.createElement('img');

      image.src = URL.createObjectURL(file);
      photoContainer.classList.remove('is-error');
      restrictionMessage.classList.remove('is-error');
      photoPreview.appendChild(image);
    }
  };

  if (fileInput) {
    fileInput.addEventListener('change', loadPhoto);
  }
};

export default uploadUserPhoto;
