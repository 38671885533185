const mobileNavToggle = () => {
  const burgerBtn = document.getElementById('burger-btn');
  const mobileMenu = document.getElementById('mobile-menu');
  const mobileNav = document.getElementById('mobile-nav');
  const closeNavBtn = document.getElementById('mobile-menu-close');

  if (!mobileMenu) return;

  const anchors = [...mobileNav.getElementsByClassName('nav__link')].filter((link) =>
    link.getAttribute('href').includes('#'),
  );

  burgerBtn.addEventListener('click', () => {
    setTimeout(() => {
      mobileMenu.classList.add('is-opened');
    }, 100);
  });

  closeNavBtn.addEventListener('click', () => {
    mobileMenu.classList.remove('is-opened');
  });

  anchors.forEach((anchor) => {
    anchor.addEventListener('click', () => {
      mobileMenu.classList.remove('is-opened');
    });
  });

  // Close nav on click outside
  document.addEventListener('click', (event) => {
    const isClosest = event.target.closest('#mobile-menu-container');

    if (!isClosest && mobileMenu.classList.contains('is-opened')) {
      mobileMenu.classList.remove('is-opened');
    }
  });
};

export default mobileNavToggle;
