// https://github.com/michu2k/Accordion
/* eslint no-new: "off" */
import Accordion from 'accordion-js';

const accordion = () => {
  const accordions = document.getElementsByClassName('accordion-container');

  if (!accordions.length) return;

  [...accordions].forEach((accordionElement) => {
    new Accordion(accordionElement, {
      duration: 200,
      showMultiple: true,
    });
  });
};

export default accordion;
