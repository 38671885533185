import { SEXES, NICKNAME_ADJECTIVES, NICKNAME_NOUNS } from '@utilities/constants';
import ClipboardJS from 'clipboard';

const isValidFileType = (file, fileTypes) => {
  if (file) {
    return fileTypes.includes(file.type);
  }

  return false;
};

const isFileSizeAcceptable = (file, size) => {
  const IMAGE_SIZE_LIMIT = 1024 * 1024 * size;

  return file.files[0].size <= IMAGE_SIZE_LIMIT;
};

const random = (arr) => {
  return arr[Math.floor(Math.random() * arr.length)];
};

const getCheckedInput = (inputs) => {
  return inputs.filter((input) => {
    return input.checked;
  });
};

const getRandomName = () => {
  const sex = random(SEXES);

  return `${random(NICKNAME_ADJECTIVES[sex])} ${random(NICKNAME_NOUNS[sex])}`;
};

/**
 * Function gets values of required fields to validate if all the fields are filled and checked
 * @param {HTMLCollection} fields
 * @returns
 */
const getRequiredFieldsValues = (fields) => {
  const fieldsValues = Array.from(fields).map((field) => {
    if (field.type === 'checkbox') {
      return field.checked;
    }

    return field.value;
  });

  return fieldsValues.every((value) => {
    return Boolean(value) === true;
  });
};

const hasRequiredFieldError = (fields) =>
  Array.from(fields).every((field) => field.classList.contains('has-error'));

const copyTextToClipboard = (callback = null) => {
  const clipboard = new ClipboardJS('.js-copy-to-clipboard-button');

  const showPopup = (popup) => {
    popup.style.display = 'block'; // eslint-disable-line

    setTimeout(() => {
      popup.style.display = 'none'; // eslint-disable-line
    }, 2000);
  };

  clipboard.on('success', (e) => {
    const popupElement = e.trigger.parentElement.querySelector('.js-copy-to-clipboard-popup');

    if (popupElement) showPopup(popupElement);

    if (callback) callback();

    e.clearSelection();
  });

  clipboard.on('error', (e) => {
    console.log(e);
  });
};

export {
  isValidFileType,
  isFileSizeAcceptable,
  random,
  getCheckedInput,
  getRandomName,
  getRequiredFieldsValues,
  hasRequiredFieldError,
  copyTextToClipboard,
};
