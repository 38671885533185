import delay from '@utilities/delay';
import modalWindow from '@common/modalWindow';

const orderDetailModal = () => {
  const options = {
    time: 500,
    callback: () => {
      modalWindow({
        modalSelector: '#telegram-modal',
        autoOpen: true,
      });
    },
  };

  delay(options);
};

export default orderDetailModal;
