import axios from 'axios';

import { copyTextToClipboard } from '../../utilities/helpers';

const fetchMessage = async (locale) => {
  const API = `${window.location.origin}/api/v1/get_achievements_creative`;

  try {
    const response = await axios.get(API);
    const message = response.data.result[`text_${locale}`];
    return message;
  } catch (error) {
    console.error(`Error fetching message: ${error}`);
    return null;
  }
};

const messageGenerator = () => {
  const achievementsMessage = document.getElementById('achievements-message');
  const refreshButton = document.getElementById('refresh-button');
  const copyButton = document.getElementById('copy-button');
  const copyButtonText = document.getElementById('copy-button-text');
  const refreshIcon = document.getElementById('refresh-icon');
  const locale = document.documentElement.lang;
  const ERROR_MESSAGE = {
    uk: 'Йой! Шо сі трапило?',
    en: 'Oops! Something went wrong',
  };
  const COPIED_MESSAGE = {
    uk: 'Скопійовано',
    en: 'Copied',
  };

  if (!achievementsMessage) return;

  const isFetching = () => {
    refreshButton.disabled = true;
    refreshIcon.classList.add('is-refreshing');
  };

  const isFetched = () => {
    refreshButton.disabled = false;
    refreshIcon.classList.remove('is-refreshing');
  };

  const getMessage = async () => {
    isFetching();

    const message = await fetchMessage(locale);

    if (message) {
      achievementsMessage.innerText = message;
      isFetched();
    } else {
      achievementsMessage.innerText = ERROR_MESSAGE[locale];
      isFetched();
    }
  };

  getMessage();

  if (refreshButton) {
    refreshButton.addEventListener('click', getMessage);
  }

  if (copyButton) {
    const initialCopyText = copyButtonText.innerText;

    copyButton.addEventListener('click', () => {
      copyTextToClipboard(() => {
        copyButtonText.innerText = COPIED_MESSAGE[locale];

        setTimeout(() => {
          copyButtonText.innerText = initialCopyText;
        }, 2000);
      });
    });
  }
};

export default messageGenerator;
