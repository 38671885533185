import modalWindow from '@common/modalWindow';

const targetedBanner = () => {
  const ROW_GAP = 12;
  const COLUMN_GAP = 8;
  const LEFT_PADDING = 16;

  const partnersContainer = document.getElementById('targeted-banner-partners');

  if (!partnersContainer) return;

  const logos = partnersContainer.querySelectorAll('.js-targeted-banner-partner');

  const changeLogosAmount = () => {
    const containerWidth = partnersContainer.clientWidth;
    const containerHeight = partnersContainer.clientHeight;
    const totalRows = Math.floor(containerHeight / (logos[0].offsetHeight + ROW_GAP));
    const rows = totalRows || 1;

    let totalWidth = 0;
    let visibleLogos = 0;

    setTimeout(() => {
      [...logos].forEach((logo) => {
        const img = logo.querySelector('img');

        totalWidth += img.offsetWidth + COLUMN_GAP;

        if (totalWidth <= (containerWidth - LEFT_PADDING) * rows) {
          visibleLogos += 1;
        }
      });

      [...logos].forEach((logo, index) => {
        if (index < visibleLogos) {
          logo.style.display = 'block'; // eslint-disable-line
        } else {
          logo.style.display = 'none'; // eslint-disable-line
        }
      });
    }, 1000);
  };

  window.addEventListener('DOMContentLoaded', changeLogosAmount);
};

const closeTargetedBanner = () => {
  const closeButton = document.getElementById('targeted-banner-close');
  const banner = document.getElementById('targeted-banner');

  if (closeButton) {
    closeButton.addEventListener('click', () => {
      banner.style.display = 'none';
      document.body.dataset.targetBannerHeight = 0;
    });
  }
};

const targetedBannerModal = () => {
  modalWindow({
    modalSelector: '#targeted-banner-modal',
    openSelector: '#show-targeted-banner-modal',
  });
};

export { targetedBanner, closeTargetedBanner, targetedBannerModal };
