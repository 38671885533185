import { isFileSizeAcceptable } from '@utilities/helpers';

const IMAGE_SIZE_MB = 10;

const formValidation = () => {
  const DATA_LAYER_FIELDS_DICTIONARY = {
    photo: 'avatar',
    contact_person: 'name',
    title: 'event_name',
    description: 'description',
    email: 'email',
  };

  const form = document.getElementById('campaign_form');
  const inputFile = document.getElementById('id_photo');
  const photoContainer = document.getElementById('photo_container');
  const restrictionMessage = document.getElementById('restriction_message');
  const submitBtn = document.getElementById('form_submit');
  const fields = document.getElementsByClassName('data-field');

  if (form) {
    /**
     * Pushing emty fields to GTM dataLayer
     */
    submitBtn.addEventListener('click', () => {
      const emptyFields = [];

      [...fields].forEach((field) => {
        if (!field.value) {
          emptyFields.push(DATA_LAYER_FIELDS_DICTIONARY[field.name]);
        }
      });

      dataLayer.push({
        event: 'celebrate_create_error',
        filled_fields: emptyFields,
      });
    });

    form.addEventListener(
      'submit',
      (event) => {
        if (inputFile.dataset.url && !inputFile.files.length) {
          dataLayer.push({
            event: 'celebrate_form',
          });
        } else if (inputFile.files.length) {
          if (!isFileSizeAcceptable(inputFile, IMAGE_SIZE_MB)) {
            photoContainer.classList.add('is-error');
            restrictionMessage.classList.add('is-error');
            event.preventDefault();
          } else {
            dataLayer.push({
              event: 'celebrate_form',
            });
          }
        } else {
          photoContainer.classList.add('is-error');
          event.preventDefault();
        }
      },
      false,
    );
  }
};

export default formValidation;
