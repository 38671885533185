const kavaImageSwitcher = () => {
  const controls = document.getElementsByClassName('js-kava-control');
  const images = document.getElementsByClassName('js-kava-image');
  const emojiBtn = document.querySelector('.js-emoji-selector');

  if (controls.length < 1) return;

  [...controls].forEach((control) => {
    const radio = control.querySelector('.js-kava-control-radio');

    radio.addEventListener('change', () => {
      [...images].forEach((image) => {
        image.classList.remove('is-active');

        if (radio.dataset.price === image.dataset.size) {
          image.classList.add('is-active');

          if (image.dataset.size === 'xxl') {
            emojiBtn.classList.add('is-xxl');
          } else {
            emojiBtn.classList.remove('is-xxl');
          }
        }
      });
    });
  });
};

export default kavaImageSwitcher;
