const popupHint = () => {
  const hintBtn = document.getElementById('doubleSumHintBtn');
  const popup = document.getElementById('doubleSumHintPopup');
  const popupClose = document.getElementById('doubleSumHintClose');

  if (!hintBtn) return;

  hintBtn.addEventListener('click', () => {
    popup.classList.add('is-active');
  });

  popupClose.addEventListener('click', () => {
    popup.classList.remove('is-active');
  });
};

export default popupHint;
