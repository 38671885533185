import React from 'react';
import classNames from 'classnames';
import { isMobileOnly } from 'react-device-detect';

import styles from './Pagination.module.scss';

const Pagination = ({
  pagination,
  currentPage,
  lastPage,
  setPage,
  setLimit,
  isPlaceholderData,
}) => {
  const hasNextData = currentPage !== lastPage;

  const handleSelectChange = (event) => {
    setPage(1);
    setLimit(Number(event.target.value));
  };

  return (
    <div className={classNames(styles.block, { [styles.hasLimit]: !isMobileOnly })}>
      {!isMobileOnly && <div />}

      <div className={classNames(styles.buttons)}>
        <div className={classNames(styles.btn)}>
          {currentPage !== 1 && (
            <button
              type="button"
              className={classNames('btn btn--secondary', styles.btn)}
              onClick={() => setPage(1)}
              disabled={currentPage === 1}
            >
              1
            </button>
          )}
        </div>

        <button
          type="button"
          aria-label="Prev"
          className={classNames('btn', styles.btn, styles.nav)}
          onClick={() => setPage((old) => Math.max(old - 1, 1))}
          disabled={currentPage === 1}
        >
          <svg className="svg-icon">
            <use href="#icon-chevron-left" />
          </svg>
        </button>

        <div className={classNames(styles.pages)}>
          {pagination.pages.map((paginationPage) => (
            <button
              key={paginationPage}
              type="button"
              className={classNames('btn btn--secondary', styles.btn, {
                'btn--dark': paginationPage === currentPage,
              })}
              onClick={() => setPage(paginationPage)}
            >
              {paginationPage}
            </button>
          ))}
        </div>

        <button
          type="button"
          aria-label="Next"
          className={classNames('btn', styles.btn, styles.nav)}
          onClick={() => {
            if (!isPlaceholderData && hasNextData) {
              setPage((old) => old + 1);
            }
          }}
          disabled={isPlaceholderData || !hasNextData}
        >
          <svg className="svg-icon">
            <use href="#icon-chevron-right" />
          </svg>
        </button>

        <div className={classNames(styles.btn)}>
          {currentPage !== lastPage && (
            <button
              type="button"
              className={classNames('btn btn--secondary', styles.btn)}
              onClick={() => setPage(lastPage)}
              disabled={currentPage === lastPage}
            >
              {lastPage}
            </button>
          )}
        </div>
      </div>

      {!isMobileOnly && (
        <div className={classNames(styles.limit)}>
          <div className={classNames(styles.select)}>
            <div className="select select--v3">
              <span className={classNames('select__label', styles.selectLabel)}>
                К-сть видимих рядків
              </span>

              <div className={classNames('select__wrapper', styles.selectWrapper)}>
                <select name="limit" className="select__default" onChange={handleSelectChange}>
                  <option value="10">10</option>
                  <option value="20">20</option>
                </select>

                <span className="select__focus" />

                <div className="select__arrow">
                  <svg className="svg-icon">
                    <use href="#icon-chevron-down" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Pagination;
