const expandable = (parent, options) => {
  const defaultOptions = {
    visibleRows: 2,
    buttonToggleClassName: 'is-expanded',
    duration: 200,
  };
  const settings = { ...defaultOptions, ...options };

  const container = parent.querySelector('.js-expandable-container');
  const button = parent.querySelector('.js-expandable-button');
  let isExpanded = false;
  let height = 0;

  if (!container) return;

  container.style.transitionDuration = `${settings.duration}ms`;
  container.style.transitionProperty = 'max-height';

  const calculateMaxHeight = () => {
    // let rowHeight = Array.from(container.children)
    //   .map((child) => child.offsetHeight + parseInt(getComputedStyle(child).marginBottom))
    //   .reduce((max, val) => Math.max(max, val), 0);

    // height = Math.ceil(container.childElementCount / container.children.length) * rowHeight * settings.visibleRows;

    for (let index = 0; index < settings.visibleRows; index += 1) {
      const child = Array.from(container.children)[index];

      if (child) {
        height += child.offsetHeight + parseInt(getComputedStyle(child).marginBottom);
      }
    }
  };

  calculateMaxHeight();

  const setMaxHeight = () => {
    container.style.maxHeight = `${height}px`;
  };

  const setFullHeight = () => {
    container.style.maxHeight = `${container.scrollHeight}px`;
  };

  setMaxHeight();

  const toggle = () => {
    if (isExpanded) {
      button.classList.remove(settings.buttonToggleClassName);
      setMaxHeight();
    } else {
      button.classList.add(settings.buttonToggleClassName);
      setFullHeight();
    }
    isExpanded = !isExpanded;
  };

  if (button) {
    button.addEventListener('click', toggle);
  }

  window.addEventListener('resize', () => {
    height = 0;
    calculateMaxHeight();

    isExpanded ? setFullHeight() : setMaxHeight();
  });
};

export default expandable;
