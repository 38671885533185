import Swiper, { EffectFade, Navigation, Virtual } from 'swiper';

import data from './donate.json';

function animateTitle(title) {
  title.innerHTML = title.innerText
    .split('')
    .map((char) => `<span class="js-char-animation">${char}</span>`)
    .join('');

  [...title.children].forEach((char, index) => {
    setTimeout(() => {
      char.style.transition = 'opacity 1s ease, filter 1s ease';
      char.classList.add('active');
    }, index * 8);
  });
}

function oneSlide(i, text) {
  return `<div class="motivation-slider__item">
    <div class="motivation-slider__pagination">${i}</div>
      <div class="motivation-slider__text">
        <div class="js-split-chars">
          ${text}
        </div>
     </div>
  </div>`;
}

function motivationCarousel() {
  const entity = document.getElementById('motivation-carousel');
  const swiper = new Swiper(entity, {
    modules: [Navigation, EffectFade, Virtual],
    speed: 600,
    effect: 'fade',
    init: false,
    fadeEffect: { crossFade: true },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    virtual: {
      slides: (function () {
        const slides = [];
        data.forEach((slide, index) => {
          slides.push(oneSlide(index + 1, String(Object.values(slide)[0]).trim()));
        });
        return slides;
      })(),
    },
  });

  swiper.on('init', (slider) => {
    animateTitle(slider.visibleSlides[0].querySelector('.js-split-chars'));
    entity.querySelector('.js-motivation-slider-random').addEventListener('click', () => {
      const destination = Math.floor(Math.random() * (Number(data.length) - 1 + 1) + 1);
      swiper.slideTo(destination, 0);
      swiper.slideNext();
      swiper.slidePrev();
    });
  });

  swiper.init();
  swiper.on('slideChange', (slider) => {
    if (slider?.visibleSlides?.[0]) {
      animateTitle(slider.visibleSlides[0].querySelector('.js-split-chars'));
    }
  });
}

export default motivationCarousel;
