import Accordion from 'accordion-js';

(() => {
  const accordions = document.getElementsByClassName('js-request-collapse');

  if (!accordions.length) return;

  [...accordions].forEach((accordion) => {
    const accInstance = new Accordion(accordion, {
      duration: 200,
      showMultiple: true,
    });

    accInstance.openAll();
  });
})();
