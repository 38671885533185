import gaCampaignCardClick from './campaignCard';
import gaCongratulationStep from './congratulation';
import gaChatBotSubscription from './chatBot';
import gaTargetedQuickDonate from './targetedCampaign';

(() => {
  gaCampaignCardClick();
  gaCongratulationStep('congratulate_step1', '.data-congrats-btn-s1');
  gaCongratulationStep('congratulate_step2', '.data-congrats-btn-s2');
  gaChatBotSubscription();
  gaTargetedQuickDonate();
})();
