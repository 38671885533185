const copyLinkToClipboard = () => {
  const block = document.getElementById('copy_to_clipboard');
  const copyTextBtn = document.getElementById('copy_to_clipboard_btn');
  const textValue = document.getElementById('copy_to_clipboard_value');
  let copyCounter = 0;

  if (block) {
    const copy = (text) => {
      navigator.clipboard.writeText(text).then(
        () => {
          block.classList.add('is-copied');

          setTimeout(() => {
            block.classList.remove('is-copied');
          }, 2000);
        },
        (err) => {
          console.error('Async: Could not copy text: ', err);
        },
      );
    };

    copyTextBtn.addEventListener('click', () => {
      copy(textValue.value);

      if (copyCounter < 1) {
        dataLayer.push({
          event: 'celebrate_copy_link',
          event_name: block.dataset.name,
          event_id: block.dataset.slug,
        });
      }

      copyCounter += 1;
    });
  }
};

export default copyLinkToClipboard;
