import Modal from 'modal-vanilla';

const videoModal = () => {
  const videoModalToggle = document.getElementById('videModalToggle');

  if (!videoModalToggle) return;

  videoModalToggle.addEventListener('click', () => {
    new Modal({ el: document.getElementById('videoModal') }).show();
  });
};

const betModal = () => {
  const buttons = document.getElementsByClassName('js-bet-modal-toggle');

  if (!buttons.length) return;

  [...buttons].forEach((button) => {
    button.addEventListener('click', () => {
      new Modal({ el: document.getElementById('betModal') }).show();
    });
  });
};

export { videoModal, betModal };
