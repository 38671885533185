import orderDetailModal from './orderDetailModal';
import addToCalendar from './addToCalendar';
import uploadUserPhoto from './uploadUserPhoto';
import formValidation from './formValidation';
import popupHint from './popupHint';
import orderChoice from './orderChoice';
import copyLinkToClipboard from './copyPageLink';
import confettiEffect from './confetti';
import showMoreGreetings from './showMoreGreetings';

(() => {
  orderDetailModal();
  addToCalendar();
  uploadUserPhoto();
  formValidation();
  popupHint();
  orderChoice();
  copyLinkToClipboard();
  confettiEffect();
  showMoreGreetings();
})();
