// https://www.npmjs.com/package/emoji-mart
import { Picker } from 'emoji-mart/dist/browser'; // eslint-disable-line

const emojiPicker = () => {
  const emojiPickers = document.getElementsByClassName('js-emoji-picker');

  if (emojiPickers.length < 1) return;

  [...emojiPickers].forEach((emoji) => {
    const emojiPickerBtn = emoji.querySelector('.js-emoji-selector');
    const emojiNative = emoji.querySelector('.js-emoji-native');

    emojiPickerBtn.addEventListener('click', () => {
      const emojiPanel = document.createElement('div');

      const pickerOptions = {
        onEmojiSelect: (data) => {
          const emojiWrapper = document.createElement('span');

          emojiPickerBtn.innerHTML = '';
          emojiWrapper.innerHTML = data.native;
          emojiPickerBtn.appendChild(emojiWrapper);
          emojiNative.value = data.native;
          emojiPanel.remove();
        },
      };

      const picker = new EmojiMart.Picker(pickerOptions);

      emoji.appendChild(emojiPanel);
      emojiPanel.appendChild(picker);
    });
  });
};

export default emojiPicker;
