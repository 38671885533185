import { CURRENCIES, CURRENCY_SUM } from '@utilities/constants';

import calculateInputWidth from './calculateInputWidth';

const paymentAmountSelection = () => {
  const MIN_INPUT_WIDTH = 0;
  const DEFAULT_BUTTON = 1;
  const paymentAmountSelections = document.querySelectorAll('.payment-amount-selection');

  // set value for the input field
  const setAmount = (input, value) => {
    input.value = value; // eslint-disable-line
  };

  // set active class for the button
  const setActiveButton = (buttonsGroup, button) => {
    buttonsGroup.forEach((btn) => {
      btn.classList.remove('is-active');
    });
    button.classList.add('is-active');
  };

  // set active class for button on change for input or select events
  const setActiveButtonOnChange = (buttonsGroup, inputValue) => {
    buttonsGroup.forEach((button) => {
      if (parseInt(inputValue) === parseInt(button.dataset.amount)) {
        button.classList.add('is-active');
      } else {
        button.classList.remove('is-active');
      }
    });
  };

  // get active button amount
  const getActiveButtonAmount = (buttonsGroup) => {
    const activeButton = Array.from(buttonsGroup).filter((button) =>
      button.classList.contains('is-active'),
    );

    // return amount from active button or set value from the default button
    return activeButton[0]
      ? parseInt(activeButton[0].dataset.amount)
      : parseInt(buttonsGroup[DEFAULT_BUTTON].dataset.amount);
  };

  // set currency and values for buttons depends on selected currency
  const setCurrency = (select, buttonsGroup, inputWrapper) => {
    const currency = select.value.toLowerCase();
    const { name, symbol } = CURRENCIES[currency];

    buttonsGroup.forEach((button, index) => {
      button.dataset.amount = CURRENCY_SUM[name][index]; // eslint-disable-line

      // swaps symbol depends on currency
      if (currency === '1') {
        button.innerHTML = `${CURRENCY_SUM[name][index]}${symbol}`; // eslint-disable-line
        inputWrapper.classList.add('is-after');
        inputWrapper.classList.remove('is-before');
      } else {
        button.innerHTML = `${symbol}${CURRENCY_SUM[name][index]}`; // eslint-disable-line
        inputWrapper.classList.add('is-before');
        inputWrapper.classList.remove('is-after');
      }
    });

    inputWrapper.dataset.currency = symbol; // eslint-disable-line
  };

  if (!paymentAmountSelections.length) return;

  Array.from(paymentAmountSelections).forEach((pas) => {
    const pasInput = pas.querySelector('.js-pas-input');
    const pasInputWrapper = pas.querySelector('.js-pas-input-wrapper');
    const pasButtons = pas.querySelectorAll('.js-pas-btn');
    const pasCurrencySelect = pas.querySelector('.js-pas-select');
    const pasButtonsGroup = pas.querySelector('.js-pas-buttons-group');

    // handle click event on fixed amount buttons
    pasButtons.forEach((button) => {
      button.addEventListener('click', () => {
        const amount = button.dataset.amount; // eslint-disable-line
        const dataLayerAmount = pasButtonsGroup.dataset.layerAmount;

        setActiveButton(pasButtons, button);
        setAmount(pasInput, amount);
        calculateInputWidth(pasInput, MIN_INPUT_WIDTH);

        dataLayer.push({
          event: dataLayerAmount,
          amount: amount,
        });
      });
    });

    // handle input event on payment amount input
    pasInput.addEventListener('input', () => {
      const regex = /[^0-9]/g;

      pasInput.value = pasInput.value.replace(regex, '');

      setActiveButtonOnChange(pasButtons, pasInput.value);
      calculateInputWidth(pasInput, MIN_INPUT_WIDTH);

      dataLayer.push({
        event: pasInput.dataset.layerInput,
        amount: pasInput.value,
      });
    });

    // change input size on erase symbols
    pasInput.addEventListener('keydown', () => {
      setTimeout(() => {
        calculateInputWidth(pasInput, MIN_INPUT_WIDTH);
      }, 0);
    });

    // handle change event on currency select
    pasCurrencySelect.addEventListener('change', () => {
      const dataLayerCurrency = pasCurrencySelect.dataset.layerCurrency;
      const currency = CURRENCIES[pasCurrencySelect.value].name.toUpperCase();

      setCurrency(pasCurrencySelect, pasButtons, pasInputWrapper);
      setAmount(pasInput, getActiveButtonAmount(pasButtons));
      setActiveButtonOnChange(pasButtons, pasInput.value);
      calculateInputWidth(pasInput, MIN_INPUT_WIDTH);

      dataLayer.push({
        event: dataLayerCurrency,
        currency: currency,
      });
    });

    // init state
    window.addEventListener('DOMContentLoaded', () => {
      const selectedAmount = getActiveButtonAmount(pasButtons);

      setCurrency(pasCurrencySelect, pasButtons, pasInputWrapper);
      setAmount(pasInput, selectedAmount);
      calculateInputWidth(pasInput, MIN_INPUT_WIDTH);
    });
  });
};

export default paymentAmountSelection;
