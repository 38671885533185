const tabs = () => {
  const tabsElement = document.getElementsByClassName('js-tabs');

  if (tabsElement.length < 1) return;

  [...tabsElement].forEach((tab) => {
    const buttons = tab.getElementsByClassName('js-tabs-btn');
    const panes = tab.getElementsByClassName('js-tabs-pane');

    [...buttons].forEach((button) => {
      if (button.classList.contains('is-active')) {
        [...panes].forEach((pane) => {
          if (pane.dataset.pane === button.dataset.nav) {
            pane.classList.add('is-active');
          }
        });
      }

      button.addEventListener('click', (event) => {
        [...panes].forEach((pane) => {
          pane.classList.remove('is-active');

          if (pane.dataset.pane === event.currentTarget.dataset.nav) {
            pane.classList.add('is-active');
          }
        });

        [...buttons].forEach((btn) => {
          btn.classList.remove('is-active');
        });

        event.currentTarget.classList.add('is-active');
      });
    });
  });
};

export default tabs;
