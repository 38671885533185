const langs = () => {
  const switchLangBtns = document.querySelectorAll('.js-switch-lang');

  const switchLang = (lang) => {
    lang === 'uk' ? (lang = '') : (lang = `/${lang}`); // eslint-disable-line

    const newPathName = window.location.pathname.replace('/en', '');
    const newPath = `${window.location.protocol}//${window.location.host}${lang}${newPathName}`;

    window.location = newPath;
  };

  [...switchLangBtns].forEach((button) => {
    button.addEventListener('click', () => {
      switchLang(button.dataset.lang);
    });
  });
};

export default langs;
