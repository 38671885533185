import React from 'react';
import classNames from 'classnames';

import styles from './CampaignStatisticsWrapper.module.scss';

const CampaignStatisticsWrapper = ({ children }) => {
  return (
    <section className={classNames('section')}>
      <div className={classNames('container', styles.container)}>
        <div className={classNames(styles.block)}>{children}</div>
      </div>
    </section>
  );
};

export default CampaignStatisticsWrapper;
