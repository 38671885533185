import React from 'react';
import classNames from 'classnames';

import formatAmountValue from '../../utils/formatAmountValue';
import { TRANSACTION_TYPES, CURRENCY } from '../../utils/constants';

import styles from './Transaction.module.scss';

const Transaction = ({ transaction }) => {
  const lang = document.documentElement.lang;
  const currency = CURRENCY[transaction.currency.toLowerCase()];
  const isNotCard = Number.isNaN(Number(transaction.sender.slice(0, 2)));
  const dateConverter = (date) => {
    const utcDate = new Date(date);
    const options = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour12: false,
      timeZone: 'Europe/Kyiv',
    };

    return utcDate.toLocaleString('uk-UA', options);
  };

  return (
    <div className={classNames(styles.transaction)}>
      <p className={classNames(styles.text, styles.textLg)}>
        {isNotCard ? (
          transaction.sender
        ) : (
          <>
            <span>{transaction.sender.slice(0, 2)}</span>
            <span className={classNames(styles.greyText)}>
              {transaction.sender.slice(2, transaction.sender.length - 2)}
            </span>
            <span>{transaction.sender.slice(-2)}</span>
          </>
        )}
      </p>

      <p className={classNames(styles.text, styles.alignRight)}>
        {dateConverter(transaction.created_at)}
      </p>

      {transaction.link ? (
        <a
          href={transaction.link}
          className={classNames(styles.text, styles.link)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {TRANSACTION_TYPES[transaction.transaction_type].tableName[lang]} {transaction.title_uk}
        </a>
      ) : (
        <p className={classNames(styles.text)}>
          {TRANSACTION_TYPES[transaction.transaction_type].tableName[lang]}
        </p>
      )}

      <p className={classNames(styles.text, styles.textLg, styles.alignRight)}>
        {transaction.currency.toLowerCase() === 'usd'
          ? `${currency}${formatAmountValue(transaction.amount)}`
          : `${formatAmountValue(transaction.amount)}${currency}`}
      </p>
    </div>
  );
};

export default Transaction;
