import Swiper, { Autoplay } from 'swiper';

const founders = () => {
  const options = {
    modules: [Autoplay],
    slidesPerView: 'auto',
    centeredSlides: true,
    spaceBetween: 40,
    freeMode: true,
    grabCursor: true,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    breakpoints: {
      992: {
        spaceBetween: 48,
      },
    },
  };

  const swiper = new Swiper('#founders', options); // eslint-disable-line
};

export default founders;
