const gaCongratulationStep = (eventName, elementName) => {
  const btn = document.querySelector(elementName);

  if (!btn) return;

  btn.addEventListener('click', () => {
    dataLayer.push({
      event: eventName,
      event_name: btn.dataset.name,
      event_id: btn.dataset.slug,
    });
  });
};

export default gaCongratulationStep;
