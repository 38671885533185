/* eslint no-unused-vars: "off" */
import Swiper, { Pagination, Autoplay } from 'swiper';

const ourPartners = () => {
  const swiper = new Swiper('#our-partners', {
    modules: [Pagination, Autoplay],
    autoHeight: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      dynamicBullets: true,
      renderBullet: function (index, className) {
        const images = [...document.getElementsByClassName('js-partner-logo')];

        // Use [index + 1] to get image source for preview if loop property is true. Otherwise use just [index]
        return `
          <button type="button" class="${className}">
            <img src="${images[index + 1].src}">
          </button>
        `;
      },
    },
    breakpoints: {
      600: {
        pagination: {
          dynamicBullets: false,
        },
      },
    },
  });
};

export default ourPartners;
