const createSelectOption = (value, text) => {
  const option = document.createElement('option');

  option.value = value;
  option.text = text;

  return option;
};

export default createSelectOption;
