const donationWidget = () => {
  const forms = document.querySelectorAll('.js-donation-form');

  if (!forms.length) return;

  const getAmount = (button) => button.dataset.amount;
  const isNotValidAmount = (inputValue) => !Number(inputValue);
  const isFirstDigitZero = (inputValue) => inputValue.length > 0 && inputValue[0] === '0';
  const setFixedAmount = (amountBtn, inputField, submit, dataLayerEvent) => {
    amountBtn.addEventListener('click', function () {
      const donation = getAmount(this);

      inputField.value = Number(inputField.value) + Number(donation); // eslint-disable-line
      submit.disabled = isNotValidAmount(inputField.value); // eslint-disable-line
      inputField.classList.add('is-valid');

      dataLayer.push({
        event: dataLayerEvent,
        amount: donation,
      });
    });
  };

  const handleDonationForm = (form) => {
    const input = form.querySelector('.js-donation-input');
    const amounts = form.querySelectorAll('.js-donation-amount');
    const submitBtn = form.querySelector('.js-donation-submit');
    const dataLayerAmountEvent = form.querySelector('.js-amounts').dataset.layerAmount;

    input.addEventListener('focus', () => {
      if (!Number(input.value)) {
        input.value = '';
      }
    });

    input.addEventListener('input', () => {
      const regex = /[^0-9]/g;

      input.value = input.value.replace(regex, '');
      submitBtn.disabled = isNotValidAmount(input.value) || isFirstDigitZero(input.value);
    });

    input.addEventListener('blur', () => {
      if (Number(input.value)) {
        input.classList.add('is-valid');
      } else {
        input.value = 0;
        input.classList.remove('is-valid');
      }
    });

    amounts.forEach((amount) => {
      setFixedAmount(amount, input, submitBtn, dataLayerAmountEvent);
    });
  };

  forms.forEach((form) => handleDonationForm(form));
};

export default donationWidget;
