import { useQuery, keepPreviousData } from '@tanstack/react-query';

import { getTransactions } from '../api/api';

const useTotalTransactionsNumber = (campaign) => {
  return useQuery({
    queryKey: ['totalTransactions', campaign],
    queryFn: () => {
      return getTransactions(campaign);
    },
  });
};

const useTransactions = (campaign, page, filter, limit, total, calculateOffsetCallback, tabs) => {
  const offset = calculateOffsetCallback(filter ? tabs[filter] : total);

  return useQuery({
    queryKey: ['transactions', campaign, page, filter, limit, offset],
    queryFn: () => {
      return getTransactions(campaign, page, filter, limit, offset);
    },
    enabled: !!total,
    placeholderData: keepPreviousData,
  });
};

export { useTransactions, useTotalTransactionsNumber };
