const backToTop = () => {
  const TOP_BUTTON_ID = 'back-to-top';
  const scrollToTopBtn = document.getElementById(TOP_BUTTON_ID);
  const rootElement = document.documentElement;

  const scrollToTop = (event, behavior = 'smooth') => {
    rootElement.scrollTo({
      top: 0,
      behavior,
    });
  };

  if (!scrollToTopBtn) return;

  scrollToTopBtn.addEventListener('click', scrollToTop);
};

export default backToTop;
